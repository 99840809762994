<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນບໍລິການ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ເພີ້ມບໍລິການ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
               <template #service_price="row">
                <td class="text-right">{{row.item.service_price | numFormat(',000.00')}}</td>
              </template>
              <template #service_month="row">
                <td v-if="row.item.service_month == 0 ">ເກັບບໍລິການລາຍເດືອນ</td>
                <td v-if="row.item.service_month == 1 ">ເກັບບໍລິການຄັ້ງດຽວ</td>
              </template>
              <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem v-on:click="onClickUpdate(row.item)">ແກ້ໄຂ</CDropdownItem>
                    <CDropdownItem :disabled="userGroup != 'administrator'" v-on:click="onClickDelete(row.item)">ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!-- Modal Create New Branch -->
    <CModal title="ຟອມເພີ້ມຂໍ້ມູນບໍລິການ" size="lg" :show.sync="modalCreate" :closeOnBackdrop="false">
      <serviceFormCreate @on_success="onSubmitCreate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <!-- Modal Update Branch -->
    <CModal title="ຟອມແກ້ໄຂຂໍ້ມູນບໍລິການ" size="lg" :show.sync="modalUpdate" :closeOnBackdrop="false">
      <serviceFormUpdate :service="serviceData" @on_success="onSubmitUpdate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import {
  getServiceAPI,
  createServiceAPI,
  updateServiceAPI,
  deleteServiceAPI
} from "@/api/service";
import serviceFormCreate from "./components/ServiceFormCreate";
import serviceFormUpdate from "./components/ServiceFormUpdate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "Service",
  components: {
    serviceFormCreate,
    serviceFormUpdate
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      modalUpdate: false,
      items: [],
      fields: [
        { key: "service_id", label: "ລະຫັດ"},
        {key: "service_name",label: "ຊື່ບໍລິການ"},
        { key: "service_detail",label: "ລາຍລະອຽດ"},
        { key: "service_price", label: "ລາຄາ"},
        { key: "service_unit",label: "ຫົວໜ່ວຍ"},
        { key: "service_month",label: "ຮູບແບບບໍລິການ"},
        { key: "actions", label: "ຈັດການ" }
      ],
      serviceData: {}
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    // Load data
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getServiceAPI().then(response => {
        this.items = response.data.data;
        this.loading = false;
      });
    },

    // Create New 
    onSubmitCreate(credentials) {
      createServiceAPI(credentials)
        .then(response => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch(error => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },

    // Click Update button
    onClickUpdate(item) {
      this.serviceData = item;
      this.modalUpdate = true;
    },
    // function update
    onSubmitUpdate(credentials) {
      updateServiceAPI(credentials)
        .then(response => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch(error => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalUpdate = false;
    },

    // Click delete button
    onClickDelete(item) {
      let onOk = () => {
        deleteServiceAPI(item.service_id)
          .then(response => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch(error => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    }
  }
};
</script>
