<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ຊື່ບໍລິການ :"
              prop="service_name"
              v-model="form.service_name"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <CInput label="ລາຄາ (ກີບ):" prop="service_price" v-model="form.service_price" />
        </CCol>
        <CCol sm="6">
          <CInput label="ຫົວໜ່ວຍ:" prop="service_unit" v-model="form.service_unit" />
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label>ຮູບແບບບໍລິການ :</label>
              <v-select
                class="is-invalid"
                prop="service_month"
                v-model="form.service_month"
                :options="serviceList"
                :reduce="code => code.code"
                label="message"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="12">
          <CTextarea
            label="ລາຍລະອຽດ :"
            prop="service_detail"
            v-model="form.service_detail"
            rows="5"
          />
        </CCol>
        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="primary"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ServiceFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      form: {
        service_name: "",
        service_detail: "",
        service_unit: "",
        service_price: "",
        service_month: "0",
      },
      serviceList: [
        { code: "0", message: "ເກັບບໍລິການລາຍເດືອນ" },
        { code: "1", message: "ເກັບບໍລິການຄັ້ງດຽວ" },
      ],
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        service_name: this.form.service_name,
        service_detail: this.form.service_detail,
        service_unit: this.form.service_unit,
        service_price: this.form.service_price,
        service_month: this.form.service_month,
        service_create_at: decrypt(this.$store.getters.getFullName),
      });
      this.form.service_name = "";
      this.form.service_detail = "";
      this.form.service_unit = "";
      this.form.service_price = "";
      this.form.service_month = "";
    },
  },
};
</script>